import { Helmet } from 'react-helmet'

import { BaseLayout } from '../layout/base'
import { Footer } from '../components/Footer'
import { ReferencesSection } from '../sections/ReferencesSection'

const ReferencePage = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>Reference | Privy</title>
      </Helmet>

      <main>
        <ReferencesSection
          sx={{ background: 'greenGradient' }}
          showList
        />
      </main>

      <Footer />
    </BaseLayout>
  )
}

export default ReferencePage
