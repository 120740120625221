import { ImageDataLike } from 'gatsby-plugin-image'

export interface IReference {
  referenceId: number
  name?: string
  subtitle?: string
  text?: string
  image?: ImageDataLike | undefined
  imageString?: string
}

export const references: Array<IReference> = [
  {
    referenceId: 1,
    name: 'Pavel a Jana',
    imageString: 'PavelJana',
    subtitle: 'Praha',
    text: 'Kvůli astronomickému zdražení energií a růstu hypotečních splátek jsme se dostali do finanční pasti. Do toho se přidaly i osobní problémy, ve finále tak naše měsíční náklady znatelně převyšovaly příjmy. Nebyli jsme tak schopni splácet naše závazky a hrozilo, že o nemovitost přijdeme. Zustatvesvem.cz od nás naštěstí za férových podmínek dům vykoupili a nechali nás v něm nadále bydlet. Platíme nájemné, které si můžeme dovolit a náš rozpočet je opět v rovnováze. Moc děkujeme za velice rychlé a slušné jednání. Doporučujeme všem, kteří se dostali díky zdražení úroků a energií do podobné situace jako my. Za pár let, až se situace uklidní, plánujeme dům koupit zpět.',
  },
  {
    referenceId: 2,
    name: 'Martin',
    imageString: 'Martin',
    subtitle: 'Plzeň',
    text: 'Dobrý den, covid mě dostal na úplné dno. Kvůli propadu příjmů jsem nebyl schopný splácet mé dluhy. Následovaly exekuce, kdy hrozila nucená dražba mého bytu. Banky a všichni ostatní se ke mně otočili zády, nikdo mi v tehdejší nejistotě nechtěl půjčit. Tato společnost vykoupila můj byt těsně před dražbou a vyplatila veškeré dluhy a exekuce. V bytě jsem zůstal nadále bydlet v nájmu. Vše vyřídili za mě, a to včetně nepříjemné komunikace s mými věřiteli a exekutory. Ještě jednou děkuji za záchranu. Martin',
  },
  {
    referenceId: 3,
    name: 'Milada',
    imageString: 'Milada',
    subtitle: 'Brno',
    text: 'Vše začalo u nerozvážné půjčky, která byla nad mé možnosti. Následovala exekuce, insolvence a už jsem se smířila s tím, že budu muset odejít z rodného domu, který jsem zdědila po rodičích. Vůbec mě nenapadlo, že mi někdo může pomoci z mé situace. Naštěstí jsem narazila na společnost privy.cz a vše se otočilo o 360 stupňů. Do dnes tomu nemohu uvěřit, ale v domě bydlím pořád, nemám žádné dluhy a platím pouze nájemné. Nikdo už mě vyhazovat nebude a mohu zde zůstat, jak dlouho budu chtít. Lidi, pokud máte podobné problémy, oslovte je a oni vše vyřeší. Milada',
  },
  {
    referenceId: 4,
    name: 'Jaroslav a Světlana',
    imageString: 'Duchodci',
    subtitle: 'Znojmo',
    text: 'Dobrý den, v téměř 70letech jsme uvažovali o prodeji našeho domu, abychom si užili důstojné stáří. Vzhledem k tomu, že zde bydlíme 40 let, nedokázali jsme si představit stěhování někam jinam. Společnost Privy jednoduše vyřešila náš problém. Odkoupili dům, vyplatili prodejní cenu na účet a my jsme v domě zůstali dále bydlet za nájemné. Tím se nám rozvázaly ruce, užíváme si důchodu naplno a uskutečňujeme to, co jsme dlouhé roky odkládali. Konečně cestujeme, ale vždy se vracíme tam, kde jsme celý život zvyklí. ',
  },
  {
    referenceId: 5,
    name: 'Jana',
    imageString: 'Jana',
    subtitle: 'Liberec',
    text: 'Moc děkuji týmu ze společnosti privy.cz za jejich vstřícnost a trpělivost. Vzhledem ke všem dluhům a exekucím, které jsem měla, jsem byla podle jejich slov „jeden z nejsložitějších případů, co kdy museli řešit“. Naštěstí vše dobře dopadlo. Vyplatili mých 13 exekucí, které vznikly z půjček na kliknutí, z nezaplacených faktur za energie, telefon a podobně. Málem jsem skončila na ulici! Jen díky nim dnes v klidu bydlím ve svém bytě a platím férové nájemné. Navíc všechno, ale opravdu všechno, vyřídili za mě. Ještě jednou děkuji!',
  },
]
