import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import type { ImageDataLike } from 'gatsby-plugin-image'
import { Box, Grid, GridProps, Text } from 'theme-ui'

import { Link } from './Link'
import { IReference } from '../data/references'

interface ReferenceProps extends IReference, GridProps {
  index?: number
}

interface ReferencesProps extends GridProps {
  isCarousel?: boolean
}

export const ReferenceCard = ({
  index = 1,
  referenceId,
  name,
  subtitle,
  image,
  imageString,
  sx,
}: ReferenceProps) => {
  const gatsbyImage = image && getImage(image)
  const link = `/reference#reference-${referenceId}`

  return (
    <Grid
      sx={{
        gap: 4,
        placeContent: 'start',
        alignContent: 'space-between',
        scrollSnapAlign: 'center',
        ...sx,
      }}
      data-sal="fade"
      data-sal-duration="300"
      data-sal-delay={index * 200}
      data-sal-easing="ease"
    >
      <Grid
        sx={{ gap: 1, alignSelf: 'stretch', alignContent: 'space-between' }}
      >
        <Text
          sx={{
            alignSelf: 'stretch',
            color: 'white',
            fontSize: ['lg', null, null, 'xl'],
            fontWeight: 'bold',
            textDecoration: 'none',
          }}
        >
          {name}
        </Text>
        <Text>{subtitle}</Text>
      </Grid>

      <Grid sx={{ gap: 4 }}>
        <Link to={link} sx={{ textDecoration: 'none' }}>
          {gatsbyImage && (
            <Box
              sx={{
                borderRadius: 'xs',
                overflow: 'hidden',
                WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Source: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
                '& > div': {
                  transition: 'all 200ms !important',
                },
                '&:hover  > div': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <GatsbyImage image={gatsbyImage} alt="" />
            </Box>
          )}
        </Link>

        <Link to={link}>Přečíst doporučení</Link>
      </Grid>
    </Grid>
  )
}

export const Reference = ({
  index = 1,
  referenceId,
  name,
  text,
  subtitle,
  image,
  imageString,
  sx,
}: ReferenceProps) => {
  const gatsbyImage = image && getImage(image)

  return (
    <Grid
      id={`reference-${referenceId}`}
      sx={{
        gap: 4,
        placeContent: 'start',
        ...sx,
        minWidth: 'none',
      }}
    >
      <Grid sx={{ gap: 1 }}>
        <Text sx={{ color: 'white', fontSize: 'xl', fontWeight: 'bold' }}>
          {name}
        </Text>
        <Text>{subtitle}</Text>
      </Grid>

      {gatsbyImage && (
        <Box
          sx={{
            borderRadius: 'xs',
            overflow: 'hidden',
            WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Source: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
            '& > div': {
              transition: 'all 200ms !important',
            },
            '&:hover  > div': {
              transform: 'scale(1.05)',
            },
          }}
        >
          <GatsbyImage image={gatsbyImage} alt="" />
        </Box>
      )}

      {text}
    </Grid>
  )
}

export const References = ({
  children,
  sx,
  isCarousel = false,
  ...rest
}: ReferencesProps) => {
  return (
    <Grid
      sx={{
        maxWidth: '100%',
        overflow: isCarousel ? 'hidden' : 'visible',
        overflowX: 'auto',
        gridAutoFlow: isCarousel ? 'column' : 'row',
        gridTemplateColumns: ['1fr', null, null, 'repeat(3, 1fr)'],
        columnGap: [4, null, null, 8],
        rowGap: [8, null, null, 8],
        scrollSnapType: isCarousel ? 'x mandatory' : 'none',
        scrollPadding: isCarousel ? '10rem' : '0',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Grid>
  )
}
