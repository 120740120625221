import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Grid, Heading, Image, Text } from 'theme-ui'

import { Container } from '../components/Container'
import { Section } from '../components/Section'
import { Reference, ReferenceCard, References } from '../components/References'
import { references } from '../data/references'
import { ImageDataLike } from 'gatsby-plugin-image'
import { Link } from '../components/Link'
import { base } from '../theme'

interface ReferencesSectionProps {
  showList?: boolean
  sx?: object
}

export const ReferencesSection = ({
  showList = false,
  sx = {}
}: ReferencesSectionProps) => {
  const referenceSx = {
    maxWidth: '420px',
    minWidth: ['280px', null, null, 'calc(75rem / 3)'],
  }
  const data = useStaticQuery(graphql`
    {
      PavelJana: file(relativePath: { eq: "1-pavel-jana.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, height: 315, quality: 90)
        }
      }
      Martin: file(relativePath: { eq: "2-martin.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, height: 315, quality: 90)
        }
      }
      Milada: file(relativePath: { eq: "3-milada.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, height: 315, quality: 90)
        }
      }
      Duchodci: file(relativePath: { eq: "4-duchodci.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, height: 315, quality: 90)
        }
      }
      Jana: file(relativePath: { eq: "5-jana.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, height: 315, quality: 90)
        }
      }
    }
  `)
  const { PavelJana, Martin, Milada, Duchodci, Jana } = data
  const images: { [index: string]: ImageDataLike } = {
    PavelJana,
    Martin,
    Milada,
    Duchodci,
    Jana,
  }

  return (
    <Section
      id="doporuceni-lidi"
      sx={{
        position: 'relative',
        py: [16, null, null, 20],
        pb: ['8rem', null, null, null],
        color: 'white',
        background: 'greenGradient',
        ...sx,
      }}
    >
      <Container>
        <Grid sx={{ gap: 16 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: ['center', null, null, 'space-between'],
            }}
          >
            <Heading
              as="h2"
              sx={{
                maxWidth: [null, null, null, '60%'],
                color: 'white',
                fontSize: ['2xl', null, null, '4xl'],
              }}
              data-sal="slide-right"
              data-sal-duration="300"
              data-sal-delay="100"
              data-sal-easing="ease"
            >
              Již <strong>896 lidí</strong> jsme zachránili před exekucí
            </Heading>
          </Flex>

          <References isCarousel={!showList}>
            {references &&
              references.length &&
              references.map((reference, index) => {
                let image: ImageDataLike | undefined
                if (reference.imageString) {
                  image = images[reference.imageString]
                }

                if (showList) {
                  return (
                    <Reference
                      index={index}
                      key={reference.referenceId}
                      {...reference}
                      image={image}
                      sx={referenceSx}
                    />
                  )
                }

                return (
                  <ReferenceCard
                    index={index}
                    key={reference.referenceId}
                    {...reference}
                    image={image}
                    sx={referenceSx}
                  />
                )
              })}
          </References>

          {!showList && (
            <Text
              sx={{
                display: 'block',
                fontSize: ['2xl', null, null, 'xl'],
                textAlign: 'center',
              }}
            >
              <Link
                customStyle={{
                  ...base.buttons.white,
                  px: [8, null, null, 16],
                  py: 2,
                  boxShadow: 'whiteButton',
                  fontSize: ['lg', null, null, 'xl'],
                  textTransform: 'none',
                  transition: 'all 200ms',
                  '&:hover': {
                    backgroundColor: 'secondary',
                    color: 'white',
                  },
                }}
                to="/reference"
                data-sal="slide-up"
                data-sal-duration="300"
                data-sal-delay={800}
                data-sal-easing="ease"
              >
                Přečíst všechna doporučení
              </Link>
            </Text>
          )}

          {showList && (
            <Text
              sx={{
                display: 'block',
                fontSize: ['2xl', null, null, 'xl'],
                textAlign: 'center',
              }}
            >
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault()
                  if (typeof window !== 'undefined') {
                    window.history.back()
                  }
                }}
              >
                Zpět na hlavní stranu
              </Link>
            </Text>
          )}
        </Grid>
      </Container>
    </Section>
  )
}
